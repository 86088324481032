<template>
  <div>
    <main-menu class="z-30"></main-menu>

    <!-- opening speech -->
    <opening-speech id="kata-sambutan"></opening-speech>

    <short-introduction id="perkenalan"></short-introduction>

    <history-timeline id="sejarah"></history-timeline>

    <staff-list id="staf"></staff-list>

    <teacher-list id="pengajar"></teacher-list>

    <div class="subnav">
      <button @click="hideSubNav()" v-if="subnav.visibility">
        <fa-icon icon="times"></fa-icon>
      </button>
      <button class="show-trigger" @click="showSubNav()" v-else>
        <fa-icon icon="arrow-left"></fa-icon>
      </button>

      <div v-if="subnav.visibility">
        <a href="#kata-sambutan">Kata Sambutan</a>
        <a href="#perkenalan">Perkenalan</a>
        <a href="#sejarah">Sejarah</a><br />
        <a href="#staf">Staf</a>
        <a href="#pengajar">Tim Pengajar</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { subnav: { visibility: false } };
  },
  methods: {
    showSubNav() {
      this.subnav.visibility = true;
    },
    hideSubNav() {
      this.subnav.visibility = false;
    },
  },
  name: "Home",
  components: {
    OpeningSpeech: () => import("@/components/Home/OpeningSpeech.vue"),
    ShortIntroduction: () => import("@/components/Home/ShortIntroduction.vue"),
    MainMenu: () => import("@/components/MainMenu.vue"),
    HistoryTimeline: () => import("@/components/Home/HistoryTimeline.vue"),
    StaffList: () => import("@/components/Home/StaffList.vue"),
    TeacherList: () => import("@/components/Home/TeacherList.vue"),
  },
};
</script>

<style lang="scss" scoped>
.subnav {
  top: 50%;
  transform: translateY(-50%);

  @apply z-20 fixed right-0 text-right w-36;

  button {
    @apply inline-block text-right py-2 px-4 mb-2 shadow bg-yellow-300;

    &.show-trigger {
      @apply rounded-l-full py-4;
    }
  }

  a {
    @apply inline-block text-right p-2 mb-2 shadow bg-blue-200;

    &::before {
      content: "# ";
    }
  }
}
</style>